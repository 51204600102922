import './bootstrap';
import * as bootstrap from 'bootstrap';
import { fromJSON } from 'postcss';

window.bootstrap = bootstrap;

// Custom helpers
window.helper = function () {
    /**
     * parse error from json result
     *
     * @param {object} data         input object
     * @param {string} default_msg  default error message
     *
     * @returns {string} message of error
     */
    function returnResponseErrorMsg(data, default_msg) {
        // show error in console for debugging!
        console.log(data);
        if (data instanceof TypeError) {
            return data.message + "\n\n" + data.stack;
        }

        if (!data || typeof data != "object") {
            return default_msg;
        }

        if (data.error) {
            return data.error;
        }

        if (data.errors && data.message) {
            let errormsg = data.message + "\n";
            Object.keys(data.errors).forEach(key => {
                errormsg += key + ': ' + data.errors[key].join('; ');
            });
            return errormsg;
        }

        if (data.message) {
            return data.message;
        }

        return default_msg;
    }

    /**
     * fetchAPI();
     *   * consider as simplified xhr but using fetch
     *   * callback function called only on success response
     *   * automatically alerts errors upon failed/error responses
     *
     * @param {string} uri            url to call
     * @param {object} params         json object of parameters to post
     * @param {callback} success      function to process if response is successful
     * @param {bool} no_success_check if true, skip check for success parameter in json response
     * @param {callback} error        function to process if response is failed
     * @param {bool} no_alert         dont show any alert on errors
     *
     * @returns {void}
     */
    function fetchAPI(uri, params, success, no_success_check, err_callback, no_alert) {
        let _opt = {};
        if (typeof success === 'object') {
            _opt = success;
        }
        else if (typeof no_success_check === 'object') {
            _opt = no_success_check;
        } else {
            _opt = {
                no_success_check: no_success_check,
                error: err_callback,
                no_alert: no_alert
            };
        }

        const opt = {
            no_success_check: _opt.no_success_check || false,
            success: _opt.success || success || false,
            error: _opt.error || false,
            no_alert: _opt.no_alert || false,
            response_json: (_opt.response_json === undefined || _opt.response_json) || false,
            method: _opt.method || params ? 'POST' : 'GET',
        };

        return fetch(uri, {
            method: opt.method,
            /* fetch doesn't use cookies. use below to force use cookies */
            credentials: "same-origin",
            body: JSON.stringify(params),
            headers: {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
            }
        })
            .then(response => response.text()
                .then(text => {
                    try {
                        let data = text;
                        const contentType = response.headers.get('content-type');
                        if (opt.response_json && (!contentType || !contentType.includes('application/json'))) {
                            if (typeof opt.error === 'function') {
                                opt.error(data);
                            }

                            return opt.no_alert ? true : alert("Oops, we haven't got JSON.\n\n" + data);
                        }
                        if (opt.response_json) {
                            data = JSON.parse(text);
                        }

                        if (!opt.no_success_check && (response.status != 200 || !data || (opt.response_json && !data.success))) {
                            if (typeof opt.error === 'function') {
                                opt.error(data);
                            }

                            let _err_msg = 'ajax call returned no success';
                            if (response.status != 200 && (!data || data.success == undefined || !data.message)) {
                                _err_msg = 'Server returned error ' + response.status + ': ' + response.statusText;
                            }
                            return opt.no_alert ? true : alert(returnResponseErrorMsg(data, _err_msg));
                        }

                        // process fn_callback
                        if (opt.success !== false) {
                            opt.success(data);
                        }
                    } catch (err_msg) {
                        if (typeof opt.error === 'function') {
                            opt.error(err_msg);
                        }

                        return opt.no_alert ? true : alert(returnResponseErrorMsg(err_msg, 'error on submit'));
                    }
                }))
            .catch((err_msg) => {
                if (typeof opt.error === 'function') {
                    opt.error(err_msg);
                }
                return opt.no_alert ? true : alert("returnResponseErrorMsg(err_msg, 'error on submit')");
            });
    }

    /**
     * fetch element by given object or string
     *
     * @param {mixed} obj   object or name of id to search for
     * @param {bool}  skip  no error if element not found
     *
     * @returns object
     */
    function getObj(obj, skip) {
        if (typeof obj == 'string' && obj[0] == '#') {
            let _obj = document.getElementById(obj.substring(1));
            if (_obj == undefined) {
                if (!skip) {
                    alert('ID ' + obj + ' does not exist.');
                }
                return;
            }
            obj = _obj
        }
        return obj
    }

    /**
     * on function call
     * Add dynamic on event handler for given class
     *
     * @param {string}   obj   object or name of id/class to search for
     * @param {string}   type  type of callback
     * @param {callback} fn    function name / callback
     * @param {bool}     skip  no error if element not found
     *
     * @returns void
     */
    function on(obj, type, fn, skip) {
        obj = getObj(obj, skip);

        let objlst = [];
        if (obj instanceof Array) {
            objlst = obj;
        }
        else if (obj instanceof HTMLElement) {
            objlst = [obj];
        } else {
            objlst = document.getElementsByClassName(obj);
        }

        Array.prototype.filter.call(objlst, elem => {
            elem['on' + type] = function (e) {
                return fn.call(this, e, this);
            }
        });
    }

    /**
     * callClass function call
     * Add handler to specified class via callback.
     * Callback function should expect element as paramter
     *
     * @param {string} class_name name of class to search for
     * @param {callback} func function name / callback
     *
     * @returns void
     */
    function callClass(class_name, func) {
        Array.prototype.filter.call(document.getElementsByClassName(class_name), elem => {
            func(elem);
        });
    }

    /**
     * Convert form to javascript object
     *
     * @param {HTMLElement} form form to convert
     *
     * @returns {object} converted form
     */
    function FormToArray(form) {
        let object = {};
        for (const [key, value] of (new FormData(getObj(form))).entries()) {
            if (key.indexOf('[]') != -1) {
                key = key.substring(0, key.length - 2);
                if (object[key] == undefined) {
                    object[key] = [];
                }
                object[key].push(value);
            } else {
                object[key] = value;
            }
        }
        return object;
    }

    /**
     * Set values to form by given object
     *
     * @param {object} obj object to fill form with
     * @param {HTMLElement} form form to fill
     * @param {bool} ignoreMissing continue on missing element
     *
     * @returns void
     */
    function ArrayToForm(object, form, ignoreMissing) {
        form = getObj(form)
        form.reset();
        for (const [key, value] of Object.entries(object)) {
            const input = form.elements[key];
            if (!input) {
                if (!ignoreMissing) {
                    console.log('ERROR: HTML element with name ' + key + ' is missing in form');
                    return;
                }
                continue;
            }

            switch (input.type) {
                case 'checkbox':
                    // convert to bool
                    input.checked = !!value;
                    break;
                default:
                    // hack to set flatpickr date
                    if (input.classList.contains('flatpickr-input')) {
                        input._flatpickr.setDate(value);
                        continue;
                    }
                    input.value = value;
                    break;
            }
        }
    }

    /**
     * Ajax helper for forms
     *
     * possible options:
     *      prepare: if defined, is called as prepare method for form data - must return data which is send to host
     *      success: if defined, is called on success response with returned data
     *      error: if defined, is called on error with returned data
     *      no_success_check: if defined, check for "success" flag is skipped
     *      submitBtn: override default submit button
     *
     * if no success option is defined, current location is reloaded
     *
     * @param {mixed}  obj  object or name of id/class to search for
     * @param {object} opt  options: prepare, success, error, no_success_check, submitBtn
     *
     * @return void
     */
    function ajaxForm(obj, opt) {
        on(obj, 'submit', function (e) {
            e.preventDefault();
            let btn = null,
                spin = null,
                frm = this,
                frmData = opt.fileUpload ? new FormData(frm) : FormToArray(frm),
                headers = {
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
                };

            if (opt.validator && !opt.validator(frmData)) {
                return;
            }

            if (opt.submitBtn) {
                btn = opt.submitBtn;
            }
            else {
                for (let x = frm.elements.length - 1; x > 0; x--) {
                    let el = this.elements[x];
                    if (el.tagName == 'BUTTON' && el.type == 'submit' && (el.classList.contains('btn') || el.classList.contains('submit-btn'))) {
                        btn = el;
                        break;
                    }
                }
            }

            let removeSubmitContent = opt.removeSubmitContent !== undefined ? opt.removeSubmitContent : false,
                submitContent = btn.innerHTML;
            if (btn) {
                btn.disabled = true;
                if (removeSubmitContent) {
                    btn.innerHTML = '';
                }
                if (btn.getElementsByClassName('fa-spinner').length < 1) {
                    spin = document.createElement('span');
                    spin.className = 'fas fa fa-spinner fa-spin-pulse fa-fw';
                    btn.appendChild(spin);
                }
                else {
                    spin = btn.getElementsByClassName('fa-spinner')[0];
                    spin.classList.remove('d-none');
                }
            }

            if (opt.prepare) {
                frmData = opt.prepare(frmData, frm)
            }

            if (opt.fileUpload) {
                delete headers["Content-Type"];
            }

            let url = frm.getAttribute('action');
            if (!url) {
                alert("No action defined for this form!")
                return;
            }

            return fetch(url, {
                method: 'POST',
                /* fetch doesn't use cookies. use below to force use cookies */
                credentials: 'same-origin',
                headers: headers,
                body: opt.fileUpload ? frmData : JSON.stringify(frmData),
            })
                .then(response => response.text()
                    .then(text => {
                        try {
                            const contentType = response.headers.get('content-type');
                            if (opt.response_json && (!contentType || !contentType.includes('application/json'))) {
                                if (typeof opt.error === 'function') {
                                    opt.error(data);
                                }

                                return opt.no_alert ? true : alert("Oops, we haven't got JSON.\n\n" + data);
                            }

                            const data = JSON.parse(text);
                            btn && (btn.disabled = false);
                            spin && (spin.classList.add('d-none'));
                            if (removeSubmitContent) {
                                btn.innerHTML = submitContent;
                            }
                            if (!opt.no_success_check && (response.status != 200 || !data || !data.success)) {
                                if (typeof opt.error === 'function') {
                                    opt.error(data);
                                }

                                let _err_msg = 'ajax call returned no success';
                                if (response.status != 200 && (!data || data.success == undefined || !data.message)) {
                                    _err_msg = 'Server returned error ' + response.status + ': ' + response.statusText;
                                }

                                return opt.no_alert ? true : showFormErrors(obj, data);
                            }

                            if (opt.success) {
                                return opt.success(data, frm);
                            }
                            location.reload();
                        } catch (error) {
                            btn && (btn.disabled = false);
                            spin && (spin.classList.add('d-none'));
                            opt.error && opt.error(error, frm);
                            return opt.no_alert ? true : alert(returnResponseErrorMsg(error, 'error on submit'));
                        }
                    }))
                .catch((error) => {
                    btn && (btn.disabled = false);
                    spin && (spin.classList.add('d-none'));
                    opt.error && opt.error(error, frm);
                    return opt.no_alert ? true : alert(returnResponseErrorMsg(error, 'error on submit'));
                });
        });
    }

    /**
     * Show form input errors
     * Works for errors return from laravel validation
     * 
     * @param {string} frm form selector
     * @param {object} data data from laravel validation
     * @return void
     */
    function showFormErrors(frm, data) {
        let forms = document.querySelectorAll(frm);
        // Iterate on forms
        for (let form of forms) {
            // Check if visible
            if (checkVisible(form)) {
                // Iterate on errors
                for (let key in data.errors) {
                    if (data.errors.hasOwnProperty(key)) { // Avoid inherited properties
                        // Iterate on each form elements
                        for (let el of form.elements) {
                            // Skip some inputs
                            if (['button', 'hidden', 'checkbox', 'submit'].includes(el.getAttribute('type'))) {
                                continue;
                            }
                            // Show error
                            if (el.getAttribute('name') == key) {
                                el.classList.add('is-invalid');
                                el.closest('div.form-group').querySelector('.invalid-feedback').innerHTML = data.errors[key][0];
                                el.closest('div.form-group').querySelector('.invalid-feedback').classList.add('d-block');
                                continue;
                            }
                            // Hide error
                            console.log(key, el.getAttribute('name'));
                            if (data.errors[el.getAttribute('name')] === undefined && !el.classList.contains('no-validation')) {
                                el.classList.remove('is-invalid');
                                el.closest('div.form-group').querySelector('.invalid-feedback').innerHTML = '';
                                el.closest('div.form-group').querySelector('.invalid-feedback').classList.remove('d-block');
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Clear form input error display
     * 
     * @param {string} frm 
     * @return void
     */
    function clearFormErrors(frm) {
        let forms = document.querySelectorAll(frm);

        for (let form of forms) {
            if (checkVisible(form)) {
                // Iterate on each form elements
                for (let el of form.elements) {
                    // Skip some inputs
                    if (['button', 'hidden', 'checkbox', 'submit'].includes(el.getAttribute('type'))) {
                        continue;
                    }
                    el.classList.remove('is-invalid');
                    el.closest('div.form-group').querySelector('.invalid-feedback').innerHTML = '';
                    el.closest('div.form-group').querySelector('.invalid-feedback').classList.remove('d-block');
                }
            }
        }
    }

    /**
     * Check if element is visible
     * 
     * @param
     * @return
     */
    function checkVisible(elm) {
        let rect = elm.getBoundingClientRect(),
            viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
    }

    /**
     * Remove 'active' class to HTML elements
     *
     * @param {object} elements HTML elements
     *
     * @return void
     */
    function removeActiveClass(elements) {
        for (let each of elements) {
            each.classList.remove('active');
        }
    }

    let clickHandlerEvents = {};

    /**
     * Global handler for click events - is triggered on each click on the page!
     *
     * @param {event} e event which triggered this handler
     *
     * @returns void
     */
    function globalClickHandler(e) {
        if (!clickHandlerEvents || !e.target || !e.target.classList) {
            return;
        }

        for (let k in clickHandlerEvents) {
            if (e.target.classList.contains(k)) {
                clickHandlerEvents[k].call(e.target, e);
                return;
            }
        }
    }

    /**
     * Add new handler for given class_name
     * Is triggered if clicked element has given classname
     * Used to defined callbacks for dynamic generated items
     *
     * @param {string}   class_name   class to bind to
     * @param {callback} fn           function name / callback
     *
     * @return void
     */
    function addClickHandler(class_name, fn) {
        clickHandlerEvents[class_name] = fn;
    }

    // add global click handler on root document
    document.addEventListener('click', globalClickHandler);

    return {
        'globalClickHandler': globalClickHandler,
        'addClickHandler': addClickHandler,
        'getObj': getObj,
        'ajaxForm': ajaxForm,
        'callClass': callClass,
        'fetchAPI': fetchAPI,
        'FormToArray': FormToArray,
        'ArrayToForm': ArrayToForm,
        'on': on,
        'returnResponseErrorMsg': returnResponseErrorMsg,
        'removeActiveClass': removeActiveClass,
        'showFormErrors': showFormErrors,
        'clearFormErrors': clearFormErrors
    }
}();

// Polyfill as per JS documentation if Object.entries is undefined/unsupported by browser OR overloaded/reused by another library
if (!Object.entries) {
    Object.entries = function (obj) {
        var ownProps = Object.keys(obj),
            i = ownProps.length,
            resArray = new Array(i); // preallocate the Array

        while (i--) {
            resArray[i] = [ownProps[i], obj[ownProps[i]]];
        }
        return resArray;
    };
}

// Modal functions
window.modal = function () {
    /**
     * Show modal
     * 
     * @param {mixed} el 
     */
    function show(el) {
        const modalEl = document.getElementById(el),
            // modal = bootstrap.Modal.getInstance(modalEl);
            modal = new bootstrap.Modal(modalEl);
        modal.show();
    }

    /**
     * Hide modal
     * 
     * @param {mixed} el 
     */
    function hide(el) {
        const modalEl = document.getElementById(el),
            modal = bootstrap.Modal.getInstance(modalEl);
        modal.hide();
    }

    return {
        'show': show,
        'hide': hide
    }
}();

// Initialize bootstrap toast
window.toastElList = [].slice.call(document.querySelectorAll('.toast'));
window.toastList = toastElList.map(function (toastEl) {
    return new bootstrap.Toast(toastEl)
});

// Toast function
window.toast = function () {
    /**
     * Show toast message
     * 
     * @param {string} msg message to show
     * @param {boolean} success flag to change color scheme: true - success, false -danger
     */
    function show(msg, success) {
        if (success) {
            toastList[0]._element.classList.remove('bg-danger');
            toastList[0]._element.classList.add('bg-success');
        } else {
            toastList[0]._element.classList.remove('bg-success');
            toastList[0]._element.classList.add('bg-danger');
        }
        toastList[0]._element.querySelector('#toast-msg').innerHTML = msg;
        toastList[0].show();
    }

    return {
        'show': show,
    }
}();

window.erralert = function () {
    /**
     * Show error alert
     * 
     * @param {string} type 
     * @param {string} message 
     */
    function show(type, message) {
        let el = document.getElementById('err-msg-' + type),
            alerts = document.getElementsByClassName('err-alerts');

        // Hide other err alerts
        for (let each of alerts) {
            each.classList.add('d-none');
        }

        el.innerHTML = message;
        el.closest('div.alert').classList.remove('d-none');
        window.scrollTo(0, 0);
    }

    /**
     * Clear all err alerts
     */
    function clear() {
        let alerts = document.getElementsByClassName('err-alerts');
        for (let each of alerts) {
            each.classList.add('d-none');
        }
    }

    return {
        'show': show,
        'clear': clear
    }
}();

// Notification
window.notifs = function () {
    /**
     * Send desktop notification
     */
    function notify(title, message) {
        if (!window.Notification) {
            console.log('Browser does not support notifications.');
        } else {
            // check if permission is already granted
            if (Notification.permission === 'granted') {
                new Notification(title, {
                    body: message,
                    icon: '/images/chat.jpg',
                });
            }
        }
    }

    /**
     * Show notification counts
     */
    function loadNotifCounts() {
        helper.fetchAPI('/messages/get-notif-count', {}, {
            success: res => {
                if (document.getElementById('inbox-notif')) {
                    document.getElementById('inbox-notif').innerHTML = '';
                    if (res.inbox > 0) {
                        document.getElementById('inbox-notif').innerHTML = res.inbox;
                    }
                }

                if (document.getElementById('queue-notif')) {
                    document.getElementById('queue-notif').innerHTML = '';
                    if (res.queues > 0) {
                        document.getElementById('queue-notif').innerHTML = res.queues;
                    }
                }

                if (document.getElementById('menu-notif')) {
                    document.getElementById('menu-notif').innerHTML = '';
                    if (res.total > 0) {
                        document.getElementById('menu-notif').innerHTML = res.total;
                    }
                }
            }
        });
    }

    /**
     * Show notification count for e-wallet
     */
    function loadEwalletNotifCount() {
        helper.fetchAPI('/e-wallet/get-notif-count', {}, {
            success: res => {
                if (document.getElementById('load-request-notif')) {
                    document.getElementById('load-request-notif').innerHTML = '';
                    if (res.requests > 0) {
                        document.getElementById('load-request-notif').innerHTML = res.requests;
                    }
                }

                if (document.getElementById('ewallet-notif')) {
                    document.getElementById('ewallet-notif').innerHTML = '';
                    if (res.total > 0) {
                        document.getElementById('ewallet-notif').innerHTML = res.total;
                    }
                }
            }
        });
    }

    return {
        'notify': notify,
        'loadNotifCounts': loadNotifCounts,
        'loadEwalletNotifCount': loadEwalletNotifCount
    }
}();

window.onload = function () {
    document.getElementById('main-loader').classList.add('d-none');
    if (document.getElementById('user-id')) {
        notifs.loadNotifCounts();
        notifs.loadEwalletNotifCount();
    }

    // Request permission from user for notification
    if (!window.Notification) {
        console.log('Browser does not support notifications.');
    } else {
        // check if permission is already granted
        if (Notification.permission !== 'granted') {
            // request permission from user
            Notification.requestPermission().then(function(p) {
                console.log("Notification permission granted.");
            }).catch(function(err) {
                console.error(err);
            });
        }
    }

    // Subscribe to Pusher - Channels for only pushing notifications
    var pusherGlobal = new Pusher('554606ff2566a7bd986a', {
        cluster: 'ap1'
    });
    // Push chat notifications
    var chatChannelGlobal = pusherGlobal.subscribe('chat');
    chatChannelGlobal.bind('receive-chat', function(data) {
        let userId = document.getElementById('user-id').innerHTML;
        if (userId == data.receiverId) {
            notifs.notify('New Message', data.message);
            notifs.loadNotifCounts();
        }
    });

    // Push queue notifications
    if (document.getElementById('user-type') != 5) {
        var queueChannelGlobal = pusherGlobal.subscribe('queue');
        queueChannelGlobal.bind('receive-queue', function(data) {
            notifs.loadNotifCounts();
            notifs.notify('New Inquiry', data.message);
        });
    }

    // Push ewallet notifications
    var ewalletChannelGlobal = pusherGlobal.subscribe('ewallet');
    ewalletChannelGlobal.bind('receive-ewallet', function(data) {
        let agencyId = document.getElementById('agency-id').innerHTML;
        if (agencyId == data.agencyId) {
            notifs.loadEwalletNotifCount();
            notifs.notify('Load Request', data.message);
        }
    });
};